import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/mobile-landscape-mode.svg'


const _hoisted_1 = { id: "rotate-device-message-container" }
const _hoisted_2 = {
  class: "absolute-center full-width",
  id: "content-holder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_page_container = _resolveComponent("q-page-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Header, { class: "text-left" }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_q_img, {
          class: "q-mb-md",
          src: _imports_0,
          id: "rotation-image"
        }),
        _cache[0] || (_cache[0] = _createElementVNode("p", { id: "rotate-device-message-text" }, [
          _createTextVNode("Розверни смартфон вертикально, "),
          _createElementVNode("br"),
          _createTextVNode("щоб тобі було зручніше")
        ], -1))
      ])
    ], 512), [
      [_vShow, _ctx.isDeviceRotationMessageVisible]
    ]),
    _withDirectives(_createVNode(_component_q_page_container, { id: "page-content" }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 512), [
      [_vShow, !_ctx.isDeviceRotationMessageVisible]
    ])
  ], 64))
}