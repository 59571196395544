import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/logo.svg'


const _hoisted_1 = {
  key: 0,
  id: "operation-name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_header = _resolveComponent("q-header")!

  return (_openBlock(), _createBlock(_component_q_header, {
    id: "header",
    ref: "header"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_toolbar, { ref: "toolbar" }, {
        default: _withCtx(() => [
          _cache[0] || (_cache[0] = _createElementVNode("div", {
            style: {"display":"flex","cursor":"pointer"},
            id: "logo-container"
          }, [
            _createElementVNode("img", {
              src: _imports_0,
              id: "logo"
            }),
            _createElementVNode("div", { id: "brand-name" }, [
              _createElementVNode("div", null, "Банк"),
              _createElementVNode("div", { style: {"margin-top":"-8px"} }, "Власний Рахунок")
            ])
          ], -1)),
          (!!$props.sectionDescription)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($props.sectionDescription), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 512))
}